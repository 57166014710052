.recordteaser {
  padding-bottom: 100px;
  .record-item {
    border-radius: 8px;
    box-shadow: $lightshadow;
    .card-img-top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .card-img-left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      @include media-breakpoint-down(sm) {
        border-bottom-left-radius: 0;
        border-top-right-radius: 8px;
      }
    }
    .card-img-right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      @include media-breakpoint-down(sm) {
        border-bottom-right-radius: 0;
        border-top-left-radius: 8px;
      }
    }
    .card-text {
      color: $black !important;
    }
  }
  .record-slider {
    @include media-breakpoint-down(sm) {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 30px;
      padding-right: 5.25em;
      .swiper-slide {
        padding: 0;
      }
    }
    .swiper-slide {
      &:not(.swiper-slide-visible) {
        opacity: .8;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: initial;
    bottom: -70px;
  }
  .btn-secondary {
    color: $darkblue;
    border-color: $darkblue;
    &:hover {
      background: $darkblue000;
      border-color: $darkblue;
    }
    &:active {
      background: $darkblue100;
      border-color: $darkblue;
    }
  }
}
.record-detail-slider {
  .swiper-button-next,
  .swiper-button-prev {
    top: unset;
    bottom: 10%;
  }
}
.tx-records {
  p {
    font-size: 18px;
    line-height: 24px;
    line-height: 24px;
  }
}
.record-detail-slider {
  .swiper-button-next,
  .swiper-button-prev {
    top: unset;
    bottom: 10%;
  }
}
.tx-records {
  p {
    font-size: 18px;
    line-height: 24px;
    line-height: 24px;
  }
}
