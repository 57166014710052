.tx_solr {
  .results-wrap {
    padding-bottom: 100px;
  }
  .results-list {
    .results-entry {
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid $gray-400;
      .results-topic {
        margin-bottom: 40px;
      }
      .results-teaser {
        color: $body-color;
        .results-highlight {
          color: $deeporange;
          font-weight: bold;
        }
      }
      a {
        color: $deeporange;
      }
    }
  }

  .tx-solr-search-form-top {
    .tx-solr-autosuggest {
      overflow: auto;
    }
  }
}

.tx-solr-autosuggest {
  color: $body-color;
  background: white;
  border-radius: 5px;
  border: 1px solid $black;
  box-shadow: $lightshadow;
  padding-bottom: 20px;
  padding-top: 50px;
  top: 0;
  width: 100% !important;
  text-align: left;
  .autocomplete-group,
  .autocomplete-suggestion {
    border-bottom: 1px solid #6D7579;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    a {
      color: $body-color;;
      &:hover {
        color: $deeporange;
        text-decoration: none;
      }
    }

  }
  .autocomplete-group {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .autocomplete-suggestion {
    &:hover {
      color: $deeporange;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.tx-solr-autocomplete {
  flex: 1 1 auto;
  .form-control {
    height: 100%;
  }
  &.autocomplete-active {
    .form-control {
      border-color: transparent !important;
      box-shadow: none;
      z-index: 10000;
    }
  }
}

.solr-pagination {
  ul {
    flex-wrap: wrap;

    li {
      margin-bottom: 0 !important;

      .btn {
        padding: 0.5rem;
        width: 2.25rem;
        height: 2.25rem;
        margin-left: 0.3125rem;
        margin-right: 0.3125rem;
        &:hover {
          border: 1px solid #434c53;
        }
      }
    }
    .previous,
    .next {
      a {
        padding: .5rem;
        line-height: 1;
        display: inline-block;
        color: $body-color;
        .svg-icon {
          color: $body-color;
          --color1: $body-color;
        }
        &:hover {
          color: $deeporange;
          text-decoration: none;
          .svg-icon {
            color: $deeporange;
            --color1: $deeporange;
          }
        }
      }
    }
    .next {
      margin-left: 15px;
    }
    .previous {
      margin-right: 15px;
    }
  }
}
