.contacts {
  padding: 100px 0;
  @include media-breakpoint-down(sm) {
    padding: 66px 0;
  }
  i {
    font-size: 1.25rem;
    padding: 0 .5rem;
  }
}
.frame .contacts,
header + .contacts {
  padding-top: 0;
}
.ratio-contact {
  max-width: 180px;
  &.editor {
    max-width: 130px;
  }
  &:after {
    padding-bottom: 100% !important;
  }
  @include media-breakpoint-down(sm) {
      max-width: 130px;
      margin-top: 2rem;
  }
}

