.keyfigures {
  .keyfigures-icons {
    background: $bluedarkgradient;
    color: $white;
    padding: 2.75rem 0;
    @include media-breakpoint-down(sm) {
      padding: 1rem 0;
    }
    .fa,
    .fas,
    .fal {
      font-size: 3.24rem;
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }
  }
  .count {
    font-size: 2.4375rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }
}
