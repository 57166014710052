.main-footer-wrapper {
  background: $bluedarkgradient;
  padding-top: 100px;
  padding-bottom: 39px;
  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    padding-bottom: 22px;
  }
  color: $white;
  .nav {
    .nav-item + .nav-item {
      margin-top: 1rem;
    }
    a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
  h5 {
    margin-bottom: 50px;
    cursor: pointer;
    font-size: 1.25rem !important;
    i {
      transition: all .2s ease-in-out;
      transform: rotate(0deg);
      font-size: 2.25rem;
    }
    &.collapsed {
      i {
        transform: rotate(-180deg);
      }
    }
  }
  .social-nav {
    i {
      font-size: 1.8rem;
    }
    .nav-item + .nav-item {
      margin-left: 1rem;
      margin-top: 0;
    }
  }
  .collapse > .nav {
    margin-bottom: 50px;
  }
  @include media-breakpoint-up(md) {
    .collapse {
      display: block;
      > .nav {
        margin-bottom: 0;
      }
    }
    h5 {
      pointer-events: none;
      cursor: text;
    }
  }
}
