@media print {
  .accordion .collapse {
    display: block;
    height: auto;
  }
  .focuspoint img {
    position: relative;
  }
  .frame,
  img {
    page-break-inside: avoid;
  }
  [class*=row] {
    display: block;
    clear: both;
  }
  [class*=col] {
    display: inline-block;
    float: left;
    vertical-align: top;
  }
  .main-header {
    position: relative;
  }
  body {
    padding-top: 0 !important;
  }
}
