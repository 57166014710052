.partnerfilter {
  background: $gray-000;
  padding: 80px 0;
  .advanced {
    display: none;
  }
}
.ratio-logo {
  img {
    max-width: 283px;
/*    max-height: 76px;*/
  }
  &:after {
    padding-bottom: 26.8% !important;
  }
}
.record-item.partner {
  border-radius: 8px;
  box-shadow: $darkshadow;
  border: 1px solid $white;
  color: $black;
  &.active {
    border-color: $deeporange;
  }
  ul {
    padding-left: 1rem;
    color: $darkblue;
    margin-top: 1rem;
  }
}
#partner-form {
  min-height: 400px;
}
#partner-results {
  min-height: 700px;
}
.show {
  #partner-form,
  #partner-results {
    min-height: auto;
  }
}
.partner-map {
  min-height: 50vh;
}
