.faqs {
  .faq-item {
    border-radius: 5px;
    box-shadow: $lightshadow;
    background: $white;
    margin-bottom: 2.5rem;
    padding: .625rem 1rem;
    .faq-title {
      i {
        transition: all .2s ease-in-out;
        transform: rotate(0deg);
      }
      &.collapsed {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
