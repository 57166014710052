.hoitworks {
  padding-bottom: 100px;
  overflow: hidden;
  .ratio-howitworks {
    &:after {
      padding-bottom: 75% !important;
    }
  }
  .step-item {
    border-radius: 5px;
    box-shadow: $purpledarkshadow;
  }
  .swiper-container {
    position: static;
    margin: 0 188px 0 188px;
  }

  @include media-breakpoint-down(md) {
    .swiper-container {
      margin: 0 auto;
    }
  }
  .card-body {
    padding: 20px;
  }
  .item-img-wrapper {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: auto;
      bottom: 0;
      height: 50%;
      width: 100%;
      background: $darkgradient;
      z-index: 1;
    }
  }
  .step-title {
    position: absolute;
    bottom: 1rem;
    left: 20px;
    right: 20px;
    color: $white;
    top: auto;
    width: auto;
    height: auto;
    z-index: 1;
  }
  .swiper-slide {
    transform: scale(.9);
    transition: all .3s ease-in-out;
    &.swiper-slide-active {
      transform: scale(1);
    }
    @include media-breakpoint-down(md) {
      transform: scale(1);
    }
  }
}
.fancybox__thumbs {
  display: none;
}
.compensate-for-scrollbar {
  padding-right: 0;
}
.fancybox__toolbar,
.fancybox__nav {
  color: $orange;
}
