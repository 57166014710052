.statement {
  .swiper-wrapper {
    padding: 100px 0;
  }
  .statement-title {
    margin-bottom: 54px;
  }
  @include media-breakpoint-down(sm) {
    .swiper-button-next, .swiper-button-prev {
      top: auto;
      bottom: 18px;
    }
    .swiper-button-next {
      right: 16px;
    }
    .swiper-button-prev {
      left: auto;
      right: 70px;
    }
  }
}
.frame {
  .statement {
    .swiper-wrapper {
    padding-top: 0;
    }
  }
}
