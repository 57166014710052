:root {
  --swiper-navigation-size: 34px;
}

/*gradients*/
$bluedarkgradient: linear-gradient(82.59deg, #000055 0%, #1F153E 100%) !default;
$purpledarkgradient: linear-gradient(45deg, #3B1DA5 0%, #000055 100%) !default;
$darkgradient: linear-gradient(180deg, rgba(19, 0, 35, 0) 0%, rgba(19, 0, 35, 0.83) 92.4%);
$orangegradient: linear-gradient(225deg, #F21326 0%, #F9A600 100%) !default;
$orangegradienthover: linear-gradient(225deg, #FF4D00 0%, #FF4D00 100%) !default;
$orangegradientactive: linear-gradient(225deg, #FC8200 0%, #FC8200 100%) !default;
/*shadows*/

$darkshadow: 0px 0px 20px rgba(0, 0, 0, 0.3), 0px 6px 60px rgba(0, 0, 0, 0.18) !default;
$purpleshadow: 0px 4px 30px rgba(19, 0, 35, 0.8) !default;
$purpledarkshadow: 0px 4px 60px 10px rgba(19, 0, 35, 0.8) !default;

$btn-nob-padding-y: 1.062rem;
$btn-nob-padding-x: 1.75rem;
$btn-nobsm-padding-y: 0.531rem;
$btn-nobsm-padding-x: 1.125rem;


.frame-space-before-extra-small { padding-top: 40px !important; }
.frame-space-before-small { padding-top: 55px !important; }
.frame-space-before-small46 { padding-top: 46px !important; }
.frame-space-before-medium { padding-top: 70px !important; }
.frame-space-before-large { padding-top: 100px !important; }
.frame-space-before-extra-large { padding-top: 125px !important; }
.frame-space-after-extra-small { padding-bottom: 40px !important; }
.frame-space-after-small { padding-bottom: 55px !important; }
.frame-space-after-small46 { padding-bottom: 46px !important; }
.frame-space-after-medium { padding-bottom: 70px !important; }
.frame-space-after-large { padding-bottom: 100px !important; }
.frame-space-after-extra-large { padding-bottom: 125px !important; }

@include media-breakpoint-down(sm) {
  .frame-space-before-extra-small { padding-top: 30px !important; }
  .frame-space-before-small { padding-top: 35px !important; }
  .frame-space-before-small46 { padding-top: 30px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 70px !important; }
  .frame-space-before-extra-large { padding-top: 90px !important; }
  .frame-space-after-extra-small { padding-bottom: 30px !important; }
  .frame-space-after-small { padding-bottom: 35px !important; }
  .frame-space-after-small46 { padding-bottom: 30px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 70px !important; }
  .frame-space-after-extra-large { padding-bottom: 90px !important; }
}

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { padding-top: 30px !important; }
  .frame-space-before-small { padding-top: 35px !important; }
  .frame-space-before-small46 { padding-top: 30px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 70px !important; }
  .frame-space-before-extra-large { padding-top: 90px !important; }
  .frame-space-after-extra-small { padding-bottom: 30px !important; }
  .frame-space-after-small { padding-bottom: 35px !important; }
  .frame-space-after-small46 { padding-bottom: 30px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 70px !important; }
  .frame-space-after-extra-large { padding-bottom: 90px !important; }
}

/*@include box-shadow($generalshadow);*/

body.no-keyvisual {
  padding-top: 130px;
  .main-header {
    &:before {
      opacity: 1;
    }
  }
}

html {
  overflow: hidden;
  overflow-y: scroll;
}
// content wrapper
.content-wrapper {
  /*padding: $content-wrapper-padding 0;*/
  max-width: $max-width-content-wide;
  margin: 0 auto;
  position: relative;
}
.element-wrapper { margin-bottom: $spacer*2}
.main-footer {
  max-width: $max-width-content-wide;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.text-darkblue {
  color: $darkblue900;
}

h1,h2,h3,h4,.h1,.h2,.h3,.h4 {
  font-family: "Archivo";
  font-weight: 800;
}
.hero {
  font-size: 4.375rem ;
  line-height: 4.375rem;
  margin-bottom: 1.5rem;
}

@include media-breakpoint-down(md) {
  .hero {
    font-size: 2.4375rem;
    line-height: 3.125rem;
  }
  .lead {
    font-size: 1rem;
  }
  h1,.h1 {
    font-size: 2.0625rem;
  }
  h2,.h2 {
    font-size: 1.6875rem;
  }
  h3,.h3 {
    font-size: 1.4375rem;
  }
  h4,.h4 {
    font-size: 1.1875rem;
  }
  h5,.h5 {
    font-size: 1rem;
  }
}

.basic-title {
  font-size: $h5-font-size;
  font-family: "Open Sans";
}
h6.basic-title {
  font-size: 1rem;
}

.mw-content {
  max-width: max-content;
}

.btn {
  border-radius: 30px;
  i {
    font-size: 1.5rem;
    margin-right: 1rem;
    &.append {
      margin-left: 1rem;
      margin-right: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    font-size: $input-btn-font-size-sm;
    line-height: $input-btn-line-height-sm;
  }

  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}


.btn {
  display: inline-flex;
  align-items: center;
  &.arrow {
    &:before {
      content: "\f0a9";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
  &.people {
    &:before {
      content: "\f500";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
  &.eye {
    &:before {
      content: "\f06e";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
  &.btn-sm {
    &:before {
      font-size: 1.25rem;
    }
  }
}

.btns {
  .btn + .btn {
    margin-left: 1rem;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
  .btn {
    max-width: max-content;
  }
}
.btn-primary {
  color: $white;
  border-color: $darkblue;
  &:hover {
    background: $darkblue800;
    border-color: $darkblue800;
  }
  &:active {
    background: $darkblue700;
    border-color: $darkblue700;
  }
}

.btn-secondary {
  color: $darkblue;
  border-color: $darkblue;
  &:hover {
    background: $darkblue000;
    border-color: $darkblue;
  }
  &:active {
    background: $darkblue100;
    border-color: $darkblue;
  }
}

.btn-primary-hero {
  background-image: $orangegradient;
  border-width: 0;
  padding: $btn-nob-padding-y $btn-nob-padding-x;
  @include media-breakpoint-down(sm) {
    padding: $btn-nobsm-padding-y $btn-nobsm-padding-x;
  }
  &:hover {
    background-image: $orangegradienthover;
  }
  &:active {
    background-image: $orangegradientactive;
  }
  &.btn-sm {
    padding: $btn-nobsm-padding-y $btn-nobsm-padding-x;
  }
}

.mb-gridspacer {
  margin-bottom: $grid-gutter-width;
}

.whitebg,
.lightbg {
  background: $gray-000;
  &.showbg {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      max-height: 865px;
      top: 0;
      left: 0;
      background: url("/typo3conf/ext/cs2_prototypes/src/dist/images/vectorlight.png") no-repeat top left;
      background-size: auto 100%;
      z-index: 0;
    }
    @include media-breakpoint-down(sm) {
      &:before {
        background-size: cover;
        max-height: 500px;
      }
    }
  }
}

.whitebg {
  background: $white;
}

.frame {
  padding-top: 100px;
}

header:not(.main-header) {
  margin-bottom: 70px;
  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
  &.content-wrapper {
    padding-top: 100px;
  }
}

.frame header {
  padding-top: 0 !important;
}
.ce-bodytext header {
  padding: 0 !important;
  margin-bottom: 2rem;
}

.swiper-button-next, .swiper-button-prev {
  width: var(--swiper-navigation-size);
  background: $darkblue900;
  border-radius: 50%;
  &.orangegradient {
    background: $orangegradient;
  }
  &.swiper-button-disabled {
    opacity: 0;
  }
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 1.2rem;
  color: $white;
  font-style: initial;
}

.swiper-button-next:after {
  content: "";
}
.swiper-button-prev:after {
  content: "";
}

.pagination {
  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    left: unset;
    right: unset;
  }
  padding-top: 80px;
}

.page-link {
  text-decoration: underline;
}

.page-item.active .page-link {
  text-decoration: none;
}
.previous,.next {
  a {
    text-decoration: none;
  }
}

.darkbg {
  header,p:not(.card-text),li {
    color: $white;
  }
  &:not(.btns) {
    background: $bluedarkgradient;
  }
  &.showbg {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      max-height: 865px;
      position: absolute;
      top: 0;
      left: 0;
      background: url("/typo3conf/ext/cs2_prototypes/src/dist/images/vectordark.png") no-repeat top right;
      background-size: auto 100%;
      z-index: 0;
    }
    @include media-breakpoint-down(sm) {
      &:before {
        background-size: cover;
        max-height: 500px;
      }
    }
  }
  .btn-secondary {
    color: $white;
    border-color: $white;
    background: $darkblue;
    &:hover {
      background: $darkblue800;
      border-color: $white;
    }
    &:active {
      background: $darkblue700;
      border-color: $white;
    }
  }

  .btn-primary {
    color: $darkblue;
    background: $gray-200;
    border-color: $gray-200;
    &:hover {
      background: $darkblue000;
      border-color: $darkblue000;
    }
    &:active {
      background: $darkblue100;
      border-color: $darkblue100;
    }
  }

  .btn-primary-hero {
    color: $white;
    background-image: $orangegradient;
    border-width: 0;
    padding: $btn-nob-padding-y $btn-nob-padding-x;
    @include media-breakpoint-down(sm) {
      padding: $btn-nobsm-padding-y $btn-nobsm-padding-x;
    }
    &:hover {
      background-image: $orangegradienthover;
    }
    &:active {
      background-image: $orangegradientactive;
    }
    &.btn-sm {
      padding: $btn-nobsm-padding-y $btn-nobsm-padding-x;
    }
  }
}

.mt-5x {
  margin-top: 3.5rem;
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }
}
.mt-6 {
  margin-top: 4.5rem;
}
.mt-42 {
  margin-top: 42px;
}
.mt-52 {
  margin-top: 52px;
}
.mt-80{
  margin-top: 80px;
}
.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-80 {
  padding-bottom: 80px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-150 {
  margin-top: 150px;
}
.default-space {
  padding-top: 100px;
  padding-bottom: 100px;
  @include media-breakpoint-down(md) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.frame .default-space,
header + .default-space {
  padding-top: 0;
}

.darkblue900 {
  color: $darkblue900;
  &:hover {
    color:$darkblue900;
  }
}
.orange {
  color: $orange;
  &:hover {
    color:$orange;
  }
}
.deeporange {
  color: $deeporange;
  &:hover {
    color:$deeporange;
  }
}

.no-events {
  pointer-events: none;
}

.vh-100 {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.overflow-visible {
  overflow: visible;
}
.overglow-hidden {
  overflow: hidden;
}

.ti-image {
  box-shadow: $lightshadow;
  border-radius: 5px;
  height: auto !important;
}
.min-h-auto {
  min-height: auto !important;
}
.p-ti {
  padding: 30px !important;
  &.col-md-4 {
    flex-basis: calc(33.33333% + 30px);
    max-width: calc(33.33333% + 30px);
    @include media-breakpoint-down(md) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  &.col-md-8 {
    flex-basis: calc(66.66666% + 30px);
    max-width: calc(66.66666% + 30px);
    @include media-breakpoint-down(md) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  &.col-md-6 {
    flex-basis: calc(50% + 30px);
    max-width: calc(50% + 30px);
    @include media-breakpoint-down(md) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.ce-textpic {
  &.no-gutters {
    &.left {
      margin-left: -30px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
    &.right {
      margin-right: -30px;
      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
    }
  }
  .ce-bodytext {
    padding-top: 30px;
  }
  .swiper-button-next {
    right: 50px;
  }
  .swiper-button-prev {
    left: 50px;
  }
}

.fa-26x {
  font-size: 2.625em;
}
.fa-125x {
  font-size: 1.25em;
}

.blue-gradient {
  color: $body-color;
  display: inline-block;
  background: $purpledarkgradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.base-link,
.text-deeporange {
  color: $deeporange;
}

.black-link {
  color: $black;
  &:hover,
  i {
    color: $darkblue900;
  }
}

ul {
  &.default-list {
    padding-left: 1rem;
    color: $darkblue;
  }
}

.row-cols-lg-auto {
  @include media-breakpoint-up(lg) {
    > .col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%
    }
  }
}

.grid-container > .col-lg-4:first-of-type > .container-fluid,
.grid-container > .col-lg-6:first-of-type > .container-fluid,
.grid-container > .col-lg-8 > .container-fluid {
  padding-right: 0;
}

.grid-container > .col-lg-4 + .col-lg-8 > .container-fluid,
.grid-container > .col-lg-6 + .col-lg-6 > .container-fluid,
.grid-container > .col-lg-8 + .col-lg-4 > .container-fluid {
  padding-left: 0;
}

.grid-container > .col-lg-4 + .col-lg-4 > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.grid-container > .col-lg-4 + .col-lg-4 + .col-lg-4 > .container-fluid {
  padding-left: 0;
  padding-right: 15px;
}
