.blogteaser {
  padding-bottom: 100px;
  .blog-item {
    border-radius: 8px;
    box-shadow: $purpledarkshadow;
    .card-img-top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .date {
        font-weight: 700;
        line-height: 24px;
        left: 10px;
        bottom: 10px;
        width: initial;
        height: initial;
        top: initial;
      }
    }
    .card-text {
      p {
        color: $black !important;
      }
    }
  }
  .blog-slider {
    @include media-breakpoint-down(sm) {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 30px;
      padding-right: 5.25em;
      .swiper-slide {
        padding: 0;
      }
    }
  }
  .btn-secondary {
    color: $darkblue;
    border-color: $darkblue;
    &:hover {
      background: $darkblue000;
      border-color: $darkblue;
    }
    &:active {
      background: $darkblue100;
      border-color: $darkblue;
    }
  }
}
