.tiles {
  padding: 100px 0 3rem 0;
  @include media-breakpoint-down(sm) {
    padding: 66px 0 2rem 0;
  }
  .tile-item {
    padding: 0 1rem 3rem 1rem;
    @include media-breakpoint-down(sm) {
      padding-bottom: 2rem;
    }
  }
  .col-12 {
    @include media-breakpoint-down(sm) {
      &:nth-child(2n+2) {
        .tile-item {
          align-items: flex-end;
          text-align: right;
          .tile-icon {
            margin-right: 55px;
          }
        }
      }
    }
  }
  .tile-icon {
    font-size: 4.7rem;
    margin: 1.5rem 0;
    background: $bluedarkgradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include media-breakpoint-down(sm) {
      font-size: 2.35rem;
      margin-left: 55px;
      margin-top: 0;
    }
  }
}
.frame .tiles,
header + .tiles {
  padding-top: 0;
}
