/* original */

// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//


.hs-input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus($ignore-warning: true);

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  &.hs-input {
    appearance: none; // Fix appearance for date inputs in Safari
  }
}

select.hs-input {
  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.hs-input-file,
.hs-input-range {
  display: block;
  width: 100%;
}


// stylelint-disable-next-line no-duplicate-selectors
select.hs-input {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.hs-input {
  height: auto;
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  // Kick in the inline
  @include media-breakpoint-up(sm) {
    // Allow folks to *not* use `.form-group`
    .hs-input {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }
  }
}


/* original*/

.hs-form-field {
  margin-bottom: 2.5rem;

  label {
    font-weight: 700;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;

    .hs-form-radio {
      margin-bottom: .625rem;


      .hs-form-radio-display {
        display: inline;
        font-weight: 400;

        .hs-input {
          display: inline;
          width: auto;
          margin-right: 10px;
          opacity: 0;

        }

        span {
          position: relative;
        }

        span:before {
          position: absolute;
          top: .25rem;
          left: -1.5rem;
          display: block;
          width: 1rem;
          height: 1rem;
          pointer-events: none;
          content: "";
          background-color: #fff;
          border: #005 solid 1px;
          border-radius: 50%;
        }

        input:checked ~ span:before {
          color: $custom-control-indicator-checked-color;
          border-color: $custom-control-indicator-checked-border-color;
          @include gradient-bg($custom-control-indicator-checked-bg);
          @include box-shadow($custom-control-indicator-checked-box-shadow);
        }

        span:after {
          position: absolute;
          top: .25rem;
          left: -1.5rem;
          display: block;
          width: 1rem;
          height: 1rem;
          content: "";
          background: 50%/50% 50% no-repeat;
        }

        input:checked ~ span:after {
          background-image: escape-svg($custom-radio-indicator-icon-checked);
        }

      }
    }

    .hs-form-booleancheckbox,
    .hs-form-checkbox {
      margin-bottom: .625rem;

      .hs-form-booleancheckbox-display,
      .hs-form-checkbox-display {
        display: inline;
        font-weight: 400;

        .hs-input {
          display: inline;
          width: auto;
          margin-right: 10px;
          opacity: 0;

        }

        span {
          position: relative;
        }

        span:before {
          position: absolute;
          top: .25rem;
          left: -1.5rem;
          display: block;
          width: 1rem;
          height: 1rem;
          pointer-events: none;
          content: "";
          background-color: #fff;
          border: #005 solid 1px;
          border-radius: 5px;
        }

        input:checked ~ span:before {
          color: $custom-control-indicator-checked-color;
          border-color: $custom-control-indicator-checked-border-color;
          @include gradient-bg($custom-control-indicator-checked-bg);
          @include box-shadow($custom-control-indicator-checked-box-shadow);
        }

        span:after {
          position: absolute;
          top: .25rem;
          left: -1.5rem;
          display: block;
          width: 1rem;
          height: 1rem;
          content: "";
          background: 50%/50% 50% no-repeat;
        }
        span.hs-form-required:before,
        span.hs-form-required:after {
          content: unset;
        }

        input:checked ~ span:after {
          background-image: escape-svg($custom-checkbox-indicator-icon-checked);
        }

      }

    }


  }


  .hs-error-msgs {

    list-style-type: none;
    padding-inline-start: 0;
    margin-top: 0.25rem;

    label.hs-error-msg {

      color: $red;
      display: block;
      font-size: 1rem;
      font-weight: 400;

    }

    &~label span {
      color: $red;
    }

  }

  label~.hs-error-msgs {

  }

  .hs-input.invalid,
  .hs-input.error {
    border-color: $red;
    &:focus {
      border-color: $black;
    }

  }

}

.hs_error_rollup {
  ul {
    list-style-type: none;

    .hs-main-font-element {
      color: $red;
    }
  }
}

.hs-input,
/* original */

// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.hs-input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus($ignore-warning: true);

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  &.hs-input {
    appearance: none; // Fix appearance for date inputs in Safari
  }
}

select.hs-input {
  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.hs-input-file,
.hs-input-range {
  display: block;
  width: 100%;
}


// stylelint-disable-next-line no-duplicate-selectors
select.hs-input {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.hs-input {
  height: auto;
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  // Kick in the inline
  @include media-breakpoint-up(sm) {
    // Allow folks to *not* use `.form-group`
    .hs-input {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }
  }
}


/* original*/


.hs-input,
.form-control {
  border-radius: 5px;
  box-shadow: $lightshadow;
}
.form {
  .form-group {
    margin-bottom: 2.5rem;
  }
  .form-label {
    font-weight: $font-weight-bold;
  }
}

.hs-submit {
  margin-top: 52px;
  text-align: center!important;

  @include media-breakpoint-down(sm) {
    text-align: left!important;
  }

  .actions {

    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    user-select: none;
    background-color: $gray-000;;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);

    @include hover() {
      color: $body-color;
      text-decoration: none;
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      opacity: $btn-disabled-opacity;
      @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled) {
      cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

      &:active,
      &.active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
          @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
      }
    }


    cursor: pointer;

    color: $darkblue;
    border-color: $darkblue;
    &:hover {
      background: $darkblue000;
      border-color: $darkblue;
    }
    &:active {
      background: $darkblue100;
      border-color: $darkblue;
    }



    border-radius: 30px;
    i {
      font-size: 1.5rem;
      margin-right: 1rem;
      &.append {
        margin-left: 1rem;
        margin-right: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 0.4rem $input-btn-padding-x-sm;
      font-size: $input-btn-font-size-sm;
      line-height: $input-btn-line-height-sm;
    }

    &:focus {
      outline: none;
      box-shadow: none !important;
    }


    &:before {
      content: "\f0a9";
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      font-size: 1.5rem;
      margin-right: 1rem;
    }


    .hs-button {
      border: unset;
      background-color: transparent;
      font-weight: 700;

      @include media-breakpoint-down(sm) {
        vertical-align: top;
        margin-top:5px;
      }
    }
  }
}


.has-error {
  .invalid-feedback,
  .form-label {
    color: $red;
    display: block;
    font-size: 1rem;
  }
  .hs-input,
  .form-control {
    border-color: $red;
    &:focus {
      border-color: $black;
    }
  }
}

.custom-checkbox .custom-control-label:before {
  border-radius: 5px;
}
.custom-control {
  margin-bottom: .625rem;
}

.dropdown-toggle {
  &:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "";
    border: none;
    transition: all .25s ease-in-out;
  }
}

.bootstrap-select {
  width: 100% !important;
  transition: none !important;
  .dropdown-toggle {
    transition: none !important;
  }
  &.show {
    .dropdown-toggle {
      box-shadow: none !important;
      z-index: 1001;
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
  .dropdown-menu {
    margin: -40px 0 0 0;
    padding: 40px 0 0 0;
    box-shadow: $lightshadow;
    border-radius: 5px;
  }
  .dropdown-toggle {
    .filter-option-inner-inner,
    .filter-option {
      overflow: visible;
    }
  }
}
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  transition: none !important;
  border-color: $white;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: $black;
}
