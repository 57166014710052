.logo {
  @include media-breakpoint-down(md) {
    max-height: 40px;
    margin: 1px 0 10px 0;
  }
}

#mainsubmenu {
  margin-top: -8px;
  position: relative;
}

.overflow-hidden .main-header {
  @include media-breakpoint-down(md) {
    padding-bottom: 5rem;
    height: 100vh !important;
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}

.mainnav {
  margin-right: -.625rem;
  .line {
    display: none;
    margin-right: 2rem;
    flex-grow: 1;
    border-right: 1px solid $darkblue700;
    height: 1.5rem;
    @include media-breakpoint-down(md) {
      display: inline-block;
    }
  }
  .subtrigger {
    transition: all .2s ease-in-out;
    transform: rotate(0deg);
    font-size: 2.25rem;
    cursor: pointer;
    color: $orange;
    &.collapsed {
      color: $white;
      transform: rotate(-180deg);
    }
  }
  > .nav-item {
    &.open {
      > .nav-link {
        > span:after {
          width: 100%;
        }
      }
    }
    > .nav-link {
      font-weight: $font-weight-bold;
      color: $white;
      padding: .5rem .625rem;
      position: relative;
      span {
        position: relative;
        display: inline-block;
      }
      span:after {
        content: "";
        display: block;
        transition: all .25s ease-in-out;
        width:0;
        height: 2px;
        background: $orange;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }
      &.active,
      &:hover {
        span:after {
          width: 100%;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-right: 0;
    max-height: calc(100vh - 170px) !important;
    max-height: calc((var(--vh, 1vh) * 100) - 170px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    .content-wrapper {
      padding: 0;
    }
    .container-fluid {
      padding: 0;
    }
    .row {
      margin-left: 0;
    }
    > .nav-item {
      width: 100%;
      > .nav-link {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .nav-link {
      display: flex !important;
      align-items: center !important;
    }
    .nav-item.open {
      > .nav-link {
        > span:after {
          width: 100%;
        }
      }
    }
  }
}

.overflow-hidden {
  .metanav.mobile {
    display: flex;
  }
}

.metanav {
  &.desktop {
    margin-right: -.65625rem;
  }
  &.mobile {
    display: none;
    position: absolute;
    top: auto;
    bottom: 0;
    z-index: 1;
    padding: .5rem 0;
    &:before {
      content: "";
      position: absolute;
      left: -15px;
      right: -15px;
      bottom: 0;
      height: 100%;
      display: block;
      background: $black;
      z-index: -1;
    }
    i {
      font-size: 1.5rem;
    }
  }
  @include media-breakpoint-down(md) {
    &.desktop {
      position: absolute;
      top: 12px;
      right: 0;
      margin-right: 0;
      z-index: 1;
    }
  }
  @include media-breakpoint-down(sm) {
    top: 8px;
  }
  .nav-item {
    .nav-link {
      color: $white;
      padding: .5rem 0.65625rem;
      i {
        display: inline-block;
        width: 22px;
        text-align: center;
        font-size: 1.375rem;
        @include media-breakpoint-down(md) {
          font-size: 2.375rem;
          width: 38px;
        }
        &.orange {
          display: none;
        }
      }
    }
    &.open {
      .nav-link {
        i {
          display: none;
          &.orange {
            display: inline-block
          }
        }
      }
    }
  }
}

.main-header {
  padding-bottom: 23px;
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }
  &:before {
    transition: all .25s ease-in-out;
    content: "";
    background: $bluedarkgradient;
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    opacity: 0;
  }
}
.sticky {
  .main-header {
    &:before {
      height: 100%;
      opacity: 1;
    }
  }
}
.langnav {
  margin-left: -.625rem;
  .nav-item {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .nav-link {
      color: $white;
      font-weight: $font-weight-bold;
      padding: .5rem .625rem;
      span {
        position: relative;
      }
      span:after {
        content: "";
        display: block;
        transition: all .25s ease-in-out;
        width:0;
        height: 2px;
        background: $orange;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }
      &.active,
      &:hover {
        span:after {
          width: 100%;
        }
      }
    }
  }
}

.level-2,
.level-3 {
  .nav-item {
    .nav-link {
      color: $white;
      font-weight: $font-weight-bold;
      padding: .5rem 0;
      position: relative;
      display: inline-block;
      span {
        position: relative;
      }
      span:after {
        content: "";
        display: block;
        transition: all .25s ease-in-out;
        width:0;
        height: 2px;
        background: $orange;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }
      &.active,
      &:hover {
        span:after {
          width: 100%;
        }
      }
    }
  }
}

.level-3 {
  .nav-link {
    font-weight: $font-weight-normal !important;
  }
}

.submenu {
  @include media-breakpoint-up(lg) {
    opacity: 0;
    &.collapsing {
      transition: opacity .25s ease-in-out;
    }
    &.show {
      opacity: 1;
    }
  }
}

.nav-item.col-md-12 + .nav-item.col-md-12 {
  margin-top: 2rem;
}
