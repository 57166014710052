.keyvisual {
  .count {
    font-size: 2.4375rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }
  .keyvisual-btns {
    margin-bottom: 81px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }
  .keyvisual-image {
    position: relative;
  }
  .keyvisual-overlay {
    left: 0;
    top: 0;
  }
  .keyvisual-icons {
    background: $bluedarkgradient;
    color: $white;
    padding: 2.75rem 0;
    @include media-breakpoint-down(sm) {
      padding: 1rem 0;
    }
    .fal {
      font-size: 3.24rem;
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }
  }
}
.ratio-keyvisual {
  &:after {
    padding-bottom: 34.78% !important;

    @include media-breakpoint-down(sm) {
      padding-bottom: 134.78% !important;
    }

    @media (max-width: 1499.98px ) and (orientation: landscape) {
      padding-bottom: 44.78% !important;
    }
    @media (max-width: 1499.98px ) {
      padding-bottom: 54.78% !important;
    }
    @media (max-width: 1199.98px ) and (orientation: landscape) {
      padding-bottom: 54.78% !important;
    }
    @media (max-width: 1199.98px ) {
      padding-bottom: 64.78% !important;
    }
    @media (max-width: 991px ) and (orientation: landscape) {
      padding-bottom: 64.78% !important;
    }
    @media (max-width: 991px ) {
      padding-bottom: 72.78% !important;
    }
    @media (max-width: 767.98px ) and (orientation: landscape) {
      padding-bottom: 72.78% !important;
    }
    @media (max-width: 767.98px) {
      padding-bottom: 80.78% !important;
    }
    @media (max-width: 576.98px) AND (orientation: landscape) {
      padding-bottom: 80.78% !important;
    }
    @media (max-width: 576.98px) {
      padding-bottom: 105.78% !important;
    }
    @media (max-width:400.98px) {
      padding-bottom: 134.78% !important;
    }
  }
}
