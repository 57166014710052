

.hubspot-cta {

  max-width: $max-width-content-wide;
  margin: 0 auto;
  position: relative;
  text-align: center!important;

  .hs-cta-wrapper {
    margin-top: 52px;
    text-align: center!important;

    .hs-cta-node {
      .cta_button {

        display: inline-block;
        font-family: $btn-font-family;
        font-weight: $btn-font-weight;
        color: $body-color;
        text-align: center;
        text-decoration: if($link-decoration == none, null, none);
        white-space: $btn-white-space;
        vertical-align: middle;
        user-select: none;
        background-color: $gray-000;
        border: $btn-border-width solid transparent;
        @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
        @include transition($btn-transition);

        @include hover() {
          color: $body-color;
          text-decoration: none;
        }

        &:focus,
        &.focus {
          outline: 0;
          box-shadow: $btn-focus-box-shadow;
        }

        // Disabled comes first so active can properly restyle
        &.disabled,
        &:disabled {
          opacity: $btn-disabled-opacity;
          @include box-shadow(none);
        }

        &:not(:disabled):not(.disabled) {
          cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

          &:active,
          &.active {
            @include box-shadow($btn-active-box-shadow);

            &:focus {
              @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
            }
          }
        }


        cursor: pointer;

        color: $darkblue;
        border-color: $darkblue;
        &:hover {
          background: $darkblue000;
          border-color: $darkblue;
        }
        &:active {
          background: $darkblue100;
          border-color: $darkblue;
        }



        border-radius: 30px;
        i {
          font-size: 1.5rem;
          margin-right: 1rem;
          &.append {
            margin-left: 1rem;
            margin-right: 0;
          }
        }
        @include media-breakpoint-down(sm) {
          padding: 0.41rem $input-btn-padding-x-sm;
          font-size: $input-btn-font-size-sm;
          line-height: $input-btn-line-height-sm;
          display: inline-flex;
          align-items: center;
        }

        &:focus {
          outline: none;
          box-shadow: none !important;
        }


        &:before {
          content: "\f0a9";
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
          font-size: 1.5rem;
          margin-right: 1rem;
        }
      }

    }
  }
}


.darkbg {
  .hubspot-cta {
    .hs-cta-wrapper {
      .hs-cta-node {
        .cta_button {

          color: $white;
          border-color: $white;
          background: $darkblue;
          &:hover {
            background: $darkblue800;
            border-color: $white;
          }
          &:active {
            background: $darkblue700;
            border-color: $white;
          }

        }
      }

    }
  }
}



.hubspot.hubspot-form {
  max-width: 664px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(md) {

    form {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
